import React from "react";
import { MetaTags } from "../components/MetaTags";
import { Layout } from "../components/layout/Layout";
import { SodansumuaHero } from "../components/sections/insight/SodanSumua/SodanSumuaHero";
import { SodansumuaArticle } from "../components/sections/insight/SodanSumua/SodanSumuaArticle";

const SodanSumuaPage: React.FC = () => {
  return (
    <Layout
      colorScheme="secondary"
      heroSection={<SodansumuaHero />}
      isHeroShortBaseOnImage
      isWhite
    >
      <SodansumuaArticle />
    </Layout>
  );
};

export default SodanSumuaPage;

export const Head = () => <MetaTags title="Sodan Sumua" />;