import React from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { body } from "../../constants/fonts";

export interface LeadinBodyTextProps extends TextProps {
  children?: React.ReactNode;
}

export function LeadinBodyText(props: LeadinBodyTextProps) {
  const { children, ...chakra } = props;

  return (
    <Text
      fontWeight="600"
      fontFamily={body}
      fontSize={{
        base: "1rem",
        lg: "1.375rem",
      }}
      {...chakra}
    >
      {children}
    </Text>
  );
}
