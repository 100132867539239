import React from "react";
import { Section } from "../../../layout/Section";
import { StaticImage } from "gatsby-plugin-image";
import ImageText from "../../../ImageText";
import HeadPara from "../../../HeadPara";
import ArticleTag from "../../../ArticleTag";
import AlignmentContainer from "../../../AlignmentContainer";
import ArticleImageContainer from "../../../ArticleImageContainer";
import { BodyText } from "../../../texts/BodyText";
import ArticleTags from "../../../ArticleTags";
import { Flex, useTheme } from "@chakra-ui/react";
import { ContactMaricaCard } from "../../../cards/ContactMaricaCard";
import { Link } from "gatsby";
import { LeadinBodyText } from "../../../texts/LeadinBodyText";

export const SodansumuaArticle: React.FC = () => {
  const theme = useTheme();

  return (
    <Section>
      <Flex direction="column" gap="6.25rem">
        <AlignmentContainer>
          <HeadPara
            large
            para='John Nurminen Foundation exhibition "The Fog of War"'
          />
          <ArticleTags>
            <ArticleTag tag="Customer" value="John Nurminen Säätiö" />
            <ArticleTag tag="Project" value="Digital exhibition" />
            <ArticleTag
              tag="What we worked on"
              value="Graphic design, UI/UX design, Concept, Development"
            />
          </ArticleTags>
          <LeadinBodyText>
          The virtual Fog of War exhibition recounts the dramatic events
        of the First World War in the Gulf of Finland through three individual storylines,
         plunging into the depths to explore the hidden mysteries of
          historical wrecks lying on the seabed.
          </LeadinBodyText>
          <LeadinBodyText>
          The exhibition is based on a book of the same name, The Fog of War:
           The First World War in the Gulf of Finland, by research divers Juha ‘Roope’ Flinkman and Jouni Polkko. It serves as a teaser for the more detailed stories revealed in the book.
          </LeadinBodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt="Screenshot of the exhibition"
            src="../../../../images/insight-img-01.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="The virtual Fog of War exhibition" />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
        <BodyText>
    The project was a collaboration between John Nurminen Säätiö and Indevit,
    who worked together in the spring of 2023 on the digital exhibition
    <Link to="/jns" style={{ color: theme.colors.lightgreen, textDecoration: 'underline' }}> Baltic Sea Legacy</Link>
</BodyText>

          <BodyText>
          <span style={{ fontWeight: 'bold' }}>John Nurminen Säätiö </span>
           provided the visual and textual content,
           showcasing events from the First World War in the Gulf of Finland, as well as translations into both Finnish and English.
          </BodyText>
          <BodyText>
          <span style={{ fontWeight: 'bold' }}>Indevit </span>
          focused on front-end and back-end development for the virtual exhibition,
           including UI/UX design, concept planning, visual content, and development, ensuring compatibility across various platforms.
          </BodyText>
        </AlignmentContainer>
        <ArticleImageContainer marginTop="unset">
          <StaticImage
            alt="Person browsing the exhibition on their phone"
            src="../../../../images/insight-img-02.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Throughout the process, John Nurminen Säätiö was able to review and provide feedback on the layout and functionality." />
          </AlignmentContainer>
        </ArticleImageContainer>
        

        <AlignmentContainer>
          <HeadPara medium para="The process, from sketch to development" />
          <BodyText>
          Indevit began by drafting ideas and creating a prototype in Figma to visually represent the final concept.
           Throughout the process, John Nurminen Säätiö was able to review and provide feedback on the layout and functionality.
           The prototype served as a crucial tool, ensuring alignment on details before coding and development began.
          </BodyText>

          <BodyText>
          Once all parties were satisfied and the final prototype was approved,
           coding for the web application commenced. The process proceeded smoothly.
          </BodyText>      
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt="Screenshot of the exhibition"
            src="../../../../images/insight-img-03.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="The exhibition is based on a book of the same name, The Fog of War: The First World War in the Gulf of Finland, by research divers Juha ‘Roope’ Flinkman and Jouni Polkko." />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <BodyText>
          Throughout our collaboration, we gained significant knowledge about the history of the Gulf of Finland.
          The new book and exhibition, Fog of War, recount the stages of the First World War,
           the strategic importance of the Baltic Sea, and the fates of the ships that sank to the seabed.
          </BodyText>

          <BodyText>
          "The release of this book is sadly timely. Russia's invasion of
           Ukraine brings to mind the times and events that led to the two world wars," author Juha Flinkman notes.
          </BodyText>

          <BodyText>
          Recent tensions between Russia and Western countries have highlighted the significance of the Baltic Sea as a security-political region.
           This marine area also played a significant strategic role during the First World War.
          </BodyText>
          <BodyText>
          "Few people know what happened in the battles of the First World War in the Gulf of Finland. 
          Our goal is to bring these events from 1914 to 1918 to the attention of the general public," Flinkman says.
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt="Person browsing the exhibition on their phone"
            src="../../../../images/insight-img-04.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Our team of experts can help you with digital products from idea to launch. " />
          </AlignmentContainer>
          
        </ArticleImageContainer>

        <AlignmentContainer>
            <BodyText>
          Working alongside John Nurminen Säätiö, the guardian of the Baltic Sea, was once again an absolute pleasure. Their dedication to raising awareness about the sea's importance is admirable, 
          and we are proud to have contributed to their mission through this collaboration!
          </BodyText>
          </AlignmentContainer>
          

        

        <AlignmentContainer alignSelf="flex-start" marginTop="6.25rem">
          <HeadPara medium para="Do you want to know more? Contact us!" />
          <ContactMaricaCard />
        </AlignmentContainer>
      </Flex>
    </Section>
  );
};
